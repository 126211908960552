import 'ol/ol.css';
import formatcoords from 'formatcoords';
import {createFaLabel} from './map-helpers.js';
import {ZoomToExtent} from 'ol/control';
import {Map, View, Feature} from 'ol';
import {
  Tile as TileLayer,
  Vector as VectorLayer
} from 'ol/layer';
import Point from 'ol/geom/Point';
import {
  TileArcGISRest,
  Vector as VectorSource,
} from 'ol/source';
import {Stroke, Style, Circle, Fill} from 'ol/style';
import {
  fromLonLat,
  toLonLat,
  transformExtent
} from 'ol/proj';

export const ESRI_URL = 'https://services.arcgisonline.com/arcgis/rest/services';
export const BASES = {
  physical: `${ESRI_URL}/World_Physical_Map/MapServer`,
  topo: `${ESRI_URL}/World_Topo_Map/MapServer`,
  darkgray: `${ESRI_URL}/Canvas/World_Dark_Gray_Base/MapServer`,
  lightgray: `${ESRI_URL}/Canvas/World_Light_Gray_Base/MapServer`,
}

export class MapCore {

  constructor() {
  }

  initProperties(props) {
    this.center = null;
    this.baseMapType = props.baseMapType || null;
    this.mapTarget = props.mapTarget || null;
    this.mapLabel = props.mapLabel || null;
    this.mapProxy = props.mapProxy || null;
    this.maxSearchZoom = props.maxSearchZoom || 16;
    this.apiRoot = props.apiRoot || null;
    this.token = null;
    this.pinLayer = null;
    this.selectedLayer = 0;
  }

  getBaseId(type) {
    return String(type || '').toLowerCase() || 'lightgray';
  }

  baseMapLayer() {
    const baseId = this.getBaseId(this.baseMapType);
    return this.createTileLayer(BASES[baseId]);
  }

  createTileLayer(url, name) {
    return new TileLayer({
      source: new TileArcGISRest({url, name})
    });
  }

  mapViewObject(opts) {
    const props = {
      center: fromLonLat(opts.center || [Number(-98.585522), 39.8333333]),
      rotation: 0,
      zoom: opts.zoom || 0,
    };

    if (!!opts.maxZoom) {
      props.maxZoom = opts.maxZoom;
    }

    if (!!opts.minZoom) {
      props.minZoom = opts.minZoom;
    }

    return new View(props);
  }

  failSafeMap(layers) {
    return new Map({
      layers,
      target: this.mapTarget,
      view: new View({
        center: fromLonLat([Number(-98.585522), 39.8333333]),
        zoom: 2
      })
    });
  }

  transformMapServerExtent(extent, srcPrj='EPSG:4326', destPrj='EPSG:3857') {
    return extent ? transformExtent(this.transformSearchExtent(extent), srcPrj, destPrj) : extent;
  }

  fitMap(extent) {
    if (!this.map) {
      return;
    }

    if (!extent) {
      extent = this.map.getView().calculateExtent(this.map.getSize());
    }

    try {
      this.map.getView().fit(extent, {
        size: this.map.getSize(),
        nearest: true,
        duration: 1000
      });
    } catch (e) {}
  }

  drawMap() {
    this.map.setTarget('mapTarget');
  }

  addMapEvents() {
    if (this.onPointerDrag) {
      this.map.on('pointerdrag', this.onPointerDrag.bind(this));
    }

    if (this.onMoveStart) {
      this.map.on('movestart', this.onMoveStart.bind(this));
    }

    if (this.onMoveEnd) {
      this.map.on('moveend', this.onMoveEnd.bind(this));
    }
  }

  zoomToExtentControl(extent, iconClass = 'fas fa-expand') {
    let ctrl = new ZoomToExtent({
      extent,
      label: createFaLabel(iconClass)
    });
    ctrl.set('name', 'zoomtoextent');

    return ctrl;
  }

  zoomToSearchCandidate(candidate) {
    if (!candidate || !this.map){
      return;
    }

    let points = [];
    var marker = new Feature({
      type: 'geoMarker',
      geometry: new Point([candidate.location.x, candidate.location.y])
    });
    points.push(marker);

    let ext = this.transformSearchExtent(candidate.extent);
    this.map.getView().fit(ext, {
      size: this.map.getSize(),
      duration: 1000,
      maxZoom: this.maxSearchZoom
    });

    this.setPoints(points);
  }

  setPoints(points) {
    let layer = this.getOrMakeLayer();
    var src = layer.getSource();
    src.clear();
    if (points && points.length) {
      src.addFeatures(points);
    }
  }

  getOrMakeLayer() {
    if (!this.pinLayer) {
      let vectorSource = new VectorSource({
        features: []
      });
      var iconStyle = new Style({
        image: new Circle({
          radius: 10,
          fill: new Fill({ color: '#666666' }),
          stroke: new Stroke({ color: '#bada55', width: 2 })
        })
      });

      let vectorLayer = new VectorLayer({
        source: vectorSource,
        style: iconStyle,
        map: this.map
      });

      vectorLayer.setProperties('name', 'pin');
      this.pinLayer = vectorLayer;
    }

    return this.pinLayer;
  }

  clearPins() {
    let layer = this.getOrMakeLayer();
    var src = layer.getSource();
    src.clear();
  }

  setCenter() {
    this.map.updateSize();
    this.center = this.map.getView().getCenter();
    let lonlat = toLonLat(this.center);
    let formatted = formatcoords(lonlat, true).format('f', {decimalPlaces: 2});
    this.map.set('center', formatted);
    if (this.coord) {
      this.coord.innerHTML = formatted;
    }
    return {
      center: this.center,
      lonlat: lonlat,
      formatted: formatted
    };
  }

  transformSearchExtent(ext) {
    if (!ext) return [];
    return [ext.xmin, ext.ymin, ext.xmax, ext.ymax];
  }

  getRequestAnimationFrame() {
    return window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
  }

  setCanvasLabel() {
    this.map.once('postrender', () => {
      const canvasItem = document.querySelector(`#${this.mapTarget} canvas`);
      if (canvasItem) {
          canvasItem.setAttribute('aria-label', this.mapLabel);
          canvasItem.setAttribute('role', 'map');
      }
    });
  }
}
