import { htmlToElement } from './map-helpers.js';
import { debounce } from 'lodash';

const SUGG_ITEM_CLASS = 'suggestion-item';
const COMMON_PARAMS = 'f=json&countryCode=USA&outSR=102100';


export default class AddressSearch {
  /**
   * AddressSearch find address suggestions and zoom to candidates using ArcGIS services
   * @param {*} searchSettings Search settings node loaded from wraps config
   * @param {*} clearMethod Method to call when address field has been cleared / reset
   * @param {*} zoomMethod Method to call when an address should be zoomed to
   */
  constructor(searchSettings, clearMethod, zoomMethod) {
    this.settings = searchSettings || {};
    this.clear = clearMethod;
    this.zoom = zoomMethod;
    this.initProperties();
    this.setDomElements();
  }

  initProperties() {
    // Array to hold references to suggestion result items.
    // Used to handle eventListeners and keyboard movement
    this.suggestionElements = [];
    this.renderedSuggestions = false;

    // Bound instances of functions so they can be used and removed as bound eventListener methods
    this.boundMethods = {
      suggestionClick: this.suggestionClick.bind(this),
      suggestionKeyEvent: this.suggestionKeyEvent.bind(this),
      clickOutside: this.clickOutside.bind(this),
    };
  }

  /**
   * Create references from elements created on the cshtml template
   */
  setDomElements() {
    this.form = document.getElementById('riskForm');

    if (this.form) {
      this.searchError = document.getElementById('searchError');
      this.formButton = document.getElementById('riskFormButton');
      this.address = document.getElementById('addressField');
      this.suggestionContainer = document.getElementById('searchContainer');
      this.suggestionResults = document.getElementById('searchResults');

      if (this.formButton && this.address) {
        this.addSearchEvents();
      }
    }
  }

  /**
   * Event handler for suggestion item keydown
   * @param {*} e key event
   * @returns
   */
  suggestionKeyEvent(e) {
    const target = this.getSuggestTarget(e);
    if (!target) {
      return;
    }

    // Check for up or down arrow
    if (e.key.includes('ArrowDown') || e.key.includes('ArrowUp')) {
      // Get currently focused elements index and predict the next index based on arrow direction
      const index = parseInt(target.dataset.index);
      const next = e.key.includes('ArrowDown') ? index + 1 : index - 1;

      // If the next index is in the suggestionElements array focus it
      // Prevent default so these arrow presses do not scroll the browser
      if (next >= 0 && next < this.suggestionElements.length) {
        this.suggestionElements[next].focus();
        e.preventDefault();
      } else if (next < 0) {
        // If this was the first element focus the address instead
        this.address.focus();
        e.preventDefault();
      }
    }
  }

  /**
   * Add event listeners to the search Elements
   */
  addSearchEvents() {
    const reset = document.getElementById('riskFormResetButton');
    if (reset) {
      reset.addEventListener('click', () => {
        this.address.value = '';
        this.removeSuggestions();
        this.showSearchError(false);

        if (this.clear) {
          this.clear();
        }
      });
    }

    // If the user ever decides to manually click the search button
    this.formButton.addEventListener('click', this.processSearch.bind(this));

    // Create debounced search input method
    const slowDown = debounce(() => { this.getSuggestions(false); }, 250);

    this.address.addEventListener('input', slowDown);
    this.address.addEventListener('keydown', (e) => {
      // A keydown event may be fired as an IME via autocomplete.
      // In that case there is no "key" property on the event
      if (!e || !e.key) {
        return;
      }

      // Escape: Close suggestions
      // Enter: Select first if suggestions exist
      // Arrow Down: focus first suggestion
      if (e.key === 'Escape') {
        this.closeSuggestions();
      } else if (e.key === 'Enter' && this.suggestionElements.length) {
        this.suggestionElements[0].click();
      } else if (e.key.includes('ArrowDown') && this.suggestionElements.length) {
        this.suggestionElements[0].focus();

        // prevent browser scrolling
        e.preventDefault();
      }
    });

    // On focus check for existing suggestions and show. If none go fish.
    this.address.addEventListener('focus', (e) => {
      if (this.renderedSuggestions) {
        this.suggestionContainer.hidden = false;

        // Add clickoutside listener on the fly
        document.body.addEventListener('click', this.boundMethods.clickOutside);
      } else {
        this.getSuggestions();
      }
    });
  }

  /**
   * Handler to check for "outside" clicks and close suggestions.
   * @param {*} e click event
   * @returns
   */
  clickOutside(e) {
    // Ignore "outside" clicks in the address field so the suggestions do not open and immediately close
    if (e && e.target && e.target.id === 'addressField'){
      return;
    }

    this.closeSuggestions();
  }

  /**
   * Close the suggestions and remove the dynamic click handler
   */
  closeSuggestions() {
    this.suggestionContainer.hidden = true;
    document.body.removeEventListener('click', this.boundMethods.clickOutside);
  }

  /**
   * Check event target / event parent for suggestion item class and return. Otherwise null;
   * @param {*} e click or key event
   * @returns
   */
  getSuggestTarget(e) {
    let target = e.target;
    if (target && !target.classList.contains(SUGG_ITEM_CLASS)){
      target = target.parentElement;
    }

    return target && target.classList.contains(SUGG_ITEM_CLASS) ? target : null;
  }

  /**
   * Event handler for suggestion item clicks
   * Executes a findAddressCandidates request for a given suggestion
   * @param {*} e click event
   * @returns
   */
  suggestionClick(e) {
    const target = this.getSuggestTarget(e);
    // Get our search config properties
    const searchNode = this.settings.findAddressCandidates || {};

    if (!target || !searchNode) {
      return;
    }

    const searchExtent = searchNode.searchExtent;
    // Create a query using the singleLine and magicKey
    // https://developers.arcgis.com/rest/geocode/api-reference/geocoding-find-address-candidates.htm
    const query = `singleLine=${target.dataset.single}&magicKey=${target.dataset.magic}&${COMMON_PARAMS}&searchExtent=${encodeURIComponent(JSON.stringify(searchExtent))}`;
    fetch(`${searchNode.url}?${query}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.candidates && data.candidates.length > 0) {

          if (this.zoom) {
            this.zoom(data.candidates[0]);
          }
          this.showSearchError(false);
        } else {
          this.showSearchError();
        }
      })
    this.closeSuggestions();
    this.address.value = target.dataset.single;
    this.getSuggestions(true);
  }

  /**
   * Attempt to get suggestions
   * @param {*} secretly if true will not show found suggestions
   */
  getSuggestions(secretly = false) {
    this.removeSuggestions();
    let text = this.address.value;
    const searchNode = this.settings.suggest;

    if (!text || !searchNode) {
      return;
    }

    const searchExtent = searchNode.searchExtent;
    const query = `text=${text}&${COMMON_PARAMS}&searchExtent=${encodeURIComponent(JSON.stringify(searchExtent))}&maxSuggestions=${searchNode.maxSuggestions}`;

    fetch(`${searchNode.url}?${query}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.suggestions) {
          const noCollections = data.suggestions.filter((sugg) => !sugg.isCollection);
          if (noCollections) {
            this.createSuggestionItems(noCollections);

            this.suggestionContainer.hidden = secretly;
            if (!secretly) {
              document.body.addEventListener('click', this.boundMethods.clickOutside);
            }
          }
          this.renderedSuggestions = true;
        } else {
          this.showSearchError();
        }
      })
  }

  /**
   * Create suggestions items and bind events
   * If no results will create a message item
   * @param {*} results Array of suggestions
   */
  createSuggestionItems(results) {
    if (results && results.length) {
      results.forEach((line, i) => {
        const item = htmlToElement(`
        <button type="button"
          class="${SUGG_ITEM_CLASS}"
          data-magic="${line.magicKey}"
          data-single="${line.text}"
          data-index="${i}"
          tabindex="0"
        >
          <span class="item-label">${line.text}</span>
        </button>`);

        this.suggestionResults.appendChild(item);
        item.addEventListener('click', this.boundMethods.suggestionClick);
        item.addEventListener('keydown', this.boundMethods.suggestionKeyEvent);
        this.suggestionElements.push(item);
      });
    } else {
      const error = htmlToElement(`<span class="${SUGG_ITEM_CLASS} p-3">No suitable candidates found...</span>`)
      this.suggestionElements.push(error);
      this.suggestionResults.appendChild(error);
    }
  }

  /**
   * Click handler for "search" button.
   * @param {*} e
   */
  processSearch(e) {
    e.preventDefault();
    if (this.suggestionElements.length) {
      this.suggestionElements[0].click();
    } else {
      this.getSuggestions();
    }
  }

  /**
   * Remove the search suggestions
   */
  removeSuggestions() {
    // Clean up the eventListeners
    this.suggestionElements.forEach((ele) => {
      ele.removeEventListener('click', this.boundMethods.suggestionClick);
      ele.removeEventListener('keydown', this.boundMethods.suggestionKeyEvent);
    });

    this.suggestionElements = [];
    this.suggestionResults.innerHTML = '';
  }

  /**
   * Show or hide the generic search error
   * @param {*} show
   * @returns
   */
  showSearchError(show = true) {
    if (!this.searchError) {
      return;
    }
    this.searchError.classList[show ? 'add' : 'remove']('active');
  }
}