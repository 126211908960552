import Modal from 'bootstrap/js/dist/modal';

window.tg = window.tg || {};
window.tg.contact = window.tg.contact || {};

export default class ContactForm {
  constructor(elId = 'contactUs') {
    this.form = document.getElementById(elId);

    if (!this.form) {
      return;
    }

    this.contactButton = document.getElementById('contactUsButton');
    const modal = new Modal(document.getElementById('sent-modal'));

    this.form.onsubmit = () => {
      if(this.contactButton) {
        this.contactButton.classList.add('processing');
        this.contactButton.disabled = true;
      }
      let formData = new FormData(this.form);
      fetch('', {
        method: 'post',
        body: new URLSearchParams(formData)
      })
        .then((data) => {
          if (data.status >= 400) {
            alert('There was a problem sending your message');
          } else {
            this.form.reset();
            if (modal){
              modal.show();
            } else {
              alert('Your message has been sent');
            }
          }

          if(this.contactButton) {
            this.contactButton.classList.remove('processing');
            this.contactButton.disabled = false;
          }
        });
      return false;
    };
  }
}