import { MapCore } from './mapCore';
import DeltaE from 'delta-e';
import {
  newWMTS,
  createCoord,
  rgbToHex,
  hexToLab,
} from './map-helpers.js';

import 'ol/ol.css';
import * as interaction from 'ol/interaction';
import PinchZoom from 'ol/interaction/PinchZoom';
import { Map } from 'ol';
import { Group as LayerGroup } from 'ol/layer';
import { fromLonLat, toLonLat } from 'ol/proj';
import AddressSearch from './AddressSearch';

const RISK_BUFFER = 0.25 * 1609.344; //0.25 mi to meters
const METERS_PER_PIXEL = 29.931;
const MAP_TARGET = 'risk-map';
const MAP_LABEL = 'Risk Map';

window.tg = window.tg || {};
window.tg.riskMap = window.tg.riskMap || {};

export default class RiskMap extends MapCore {
  get imageSize() {
    return (this.riskOpts.assessImagePixelSize + (this.riskOpts.fuel ? this.riskOpts.fuel.radius * 2 : 0));
  }

  get variableRadius() {
    return this.radius < 7 ? 7 : this.radius;
  }

  constructor() {
    super();

    this.riskConfig = window.tg.riskMap;

    if (!this.riskConfig.apiRoot || !this.riskConfig.mapProxy) {
      return;
    }

    this.initProperties();

    let requestAnimationFrame = this.getRequestAnimationFrame();
    requestAnimationFrame(() => {
      if (document.getElementById(this.mapTarget) === null) {
        throw new Error(
          `Map target ${this.mapTarget} cannot be found. Render failed...`
        );
      }

      this.setDomElements();
      fetch(`${this.apiRoot}/map/getmapproxyToken`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.token = data.token;
          this.fetchSettings();
        }).catch((e) => {
          this.initMap();
        });
    });
  }

  initProperties() {
    super.initProperties({
      baseMapType: window.tg.riskMap.baseType,
      maxSearchZoom: window.tg.riskMap.maxSearchZoom,
      mapTarget: MAP_TARGET,
      mapLabel: MAP_LABEL,
      apiRoot: this.riskConfig.apiRoot,
      mapProxy: `${this.riskConfig.mapProxy}/mapproxy`
    });

    this.riskLoaded = true;
    this.fuelLoaded = true;
    this.riskPalettes = [];
    this.fuelPalette = undefined;

    this.wyrLG = null;
    this.settings = null;
    this.riskOpts = null;
  }

  setDomElements() {
    this.output = document.getElementById('risk-map-output');
    if (this.output) {
      this.coord = this.createOutputChild('risk-coord');
      this.riskLevel = this.createOutputChild('risk-level');
    }
  }

  createOutputChild(id, append = true) {
    const child = document.createElement('span');
    child.setAttribute('class', 'output-child col');
    child.id = id;

    if (this.output && append) {
      this.output.appendChild(child);
    }

    return child;
  }

  fetchSettings() {
    const fetchUrl = `${this.apiRoot}/WRAPCore/dist/public.json`;
    fetch(fetchUrl)
      .then(response => response.json())
      .then(data => {
        let riskData = null;

        const defaultLayers = [];
        data.themes.forEach((theme) => {
          const def = theme.defaultLayers;
          if (def && def.length > 0) {
            def.forEach(defLay => {
              const group = theme.layerGroups.find(lg => lg.groupName === defLay.group);
              if (group) {
                const layer = group.layerItems.find(li => li.name === defLay.layer && li.type === 'wmts');
                if (layer) {
                  defaultLayers.push(layer);
                }
              }
            })
          }
        });

        if (defaultLayers && defaultLayers.length > 0) {
          riskData = {
            layers: defaultLayers,
            options: data.options,
            risk: data.risk,
            extents: data.extents
          }
        }

        this.settings = riskData;
        this.riskOpts = riskData ? riskData.risk : null;
        this.initMap();
        this.searchArea = new AddressSearch(data.search, this.clearPins.bind(this), this.zoomToSearchCandidate.bind(this));
      })
  }

  initMap() {
    let layers = [this.baseMapLayer()];

    // Show a map centered on the US if dependencies do not load
    if (!window.tg.riskMap || !this.settings) {
      this.map = this.failSafeMap(layers);
      return;
    }

    const opts = this.settings.options;
    layers = layers.concat(this.settings.layers.map(lay => newWMTS(lay.url, this.token, lay.extent, lay.name, this.mapProxy, opts.maxZoom, parseFloat(window.tg.riskMap.layerOpacity) || 1)))

    this.map = new Map({
      layers,
      target: MAP_TARGET,
      loadTilesWhileAnimating: true,
      interactions: interaction.defaults({}).extend([
        new PinchZoom({
          constrainResolution: true
        })
      ]),
      view: this.mapViewObject(opts),
    });

    this.fitMap(this.settings.extents.default);

    this.setCanvasLabel();
    this.setCenter();
    this.setRadius();
    this.addOffscreenElements();
    this.addMapEvents();

    this.map.on('change:riskindex', evt => {
      let index = evt.target.get(evt.key);

      const riskLevel = this.riskOpts.riskLayers[this.selectedLayer].riskLevels[index];
      if (this.riskLevel) {
        this.riskLevel.innerHTML = riskLevel.level;
        this.riskLevel.style.backgroundColor = riskLevel.color;
      }
    });

    this.setupRiskLayer();

    if (this.riskOpts.clipLayerToCircle) {
      if (this.riskOpts.opacity != undefined) {
        this.wyrLG.setOpacity(this.riskOpts.opacity);
      } else {
        this.wyrLG.setOpacity(1);
      }
      this.wyrLG.setVisible(true);
      for (let riskLayer of this.riskOpts.riskLayers) {
        riskLayer.mapLayer.setVisible(false);
      }
      this.riskOpts.riskLayers[this.selectedLayer].mapLayer.setVisible(true);
    }

    this.createControls();
  }

  createControls() {
    let controls = [this.zoomToExtentControl(this.settings.extents.default)];
    controls.forEach(ctrl => this.map.addControl(ctrl));
  }

  addOffscreenElements() {
    this.addOffscreenImages();

    if (this.riskOpts.fuel) {
      let offscreenImgFuel = this.createOffscreenImage(this.riskOpts.debug ? 350 : -350);

      this.riskOpts.fuel.image = offscreenImgFuel;

      offscreenImgFuel.onload = e => {
        this.fuelLoaded = true;
        this.calculateRiskNew();
      };

      offscreenImgFuel.onloadstart = e => {
        self.fuelLoaded = false;
      };
    }

    let offscreenCanvas = document.createElement('canvas');
    offscreenCanvas.setAttribute(
      'style',
      `position: absolute; top: ${this.riskOpts.debug ? '' : '-'}500px; width:500px; border: 1px solid black; z-index: 1000;`
    );
    document.getElementsByTagName('body')[0].appendChild(offscreenCanvas);

    this.offscreenCanvas = offscreenCanvas;
  }

  addOffscreenImages() {
    let i = 0;
    for (let layer of this.riskOpts.riskLayers) {
      const image = this.createOffscreenImage(this.riskOpts.debug ? 400 + i * 50 : -400 - i * 50);
      layer.image = image;

      image.onload = e => {
        layer.loaded = true;
        this.calculateRiskNew();
      };

      image.onloadstart = e => {
        layer.loaded = false;
      };
    }
  }

  generatePalette(sourceRisk) {
    let palette = [];
    let rl = sourceRisk.riskLevels;
    for (let i = 0; i < rl.length; i++) {
      palette.push({ value: rl[i], pixelColor: rl[i].pixelColor, index: i });
    }
    return palette;
  }

  createOffscreenImage(top = -400) {
    let offscreenImg = document.createElement('img');
    offscreenImg.setAttribute(
      'style',
      `position: absolute; top: ${top}px; border: 1px solid red; z-index: 1000;`
    );
    document.getElementsByTagName('body')[0].appendChild(offscreenImg);
    return offscreenImg;
  }

  setupRiskLayer() {
    if (this.wyrLG) {
      //dont set it up again
      return;
    }

    let layers = [];
    for (let data of this.riskOpts.riskLayers) {
      let layer = null;

      // This is a change from the WRAPs which would have already added a mapLayer if it had layerName in another method
      // Because we are doing everything inside of this class that layer must be added here.
      if (data.url) {
        layer = newWMTS(
          data.url,
          this.token,
          this.riskOpts.extent,
          data.name,
          this.mapProxy,
          this.settings.options.maxZoom
        );

        if (!data.layerName) {
          layer.setVisible(false);
        }
      }

      data.mapLayer = layer;

      if (this.riskOpts.clipLayerToCircle) {
        layer.on('postrender', this.onCircleClipPostcompose.bind(this));
        layer.on('prerender', this.onCircleClipPrecompose.bind(this));
      } else {
        layer.on('prerender', this.onCircleDrawPrecompose.bind(this));
        layer.on('postrender', this.onCircleDrawPostcompose.bind(this));
      }

      layers.push(layer);
    }

    if (this.riskOpts.clipLayerToCircle) {
      let layersLG = new LayerGroup({
        layers: layers
      });

      layersLG.set('name', 'wyr');

      this.wyrLG = layersLG;

      this.map.addLayer(this.wyrLG);
    }
  }

  drawTargetInner(ctx, centerPx) {
    ctx.beginPath();
    ctx.arc(
      centerPx[0],
      centerPx[1],
      1,
      0,
      2 * Math.PI
    );
    ctx.lineWidth = 4;
    ctx.strokeStyle = 'rgba(0,0,0,1)';
    ctx.stroke();
    ctx.closePath();
  }

  drawTargetOuter(ctx, centerPx) {
    ctx.beginPath();
    let rad = this.variableRadius;
    ctx.arc(
      centerPx[0],
      centerPx[1],
      rad,
      0,
      2 * Math.PI
    );
    ctx.lineWidth = 2;
    ctx.strokeStyle = 'rgba(0,0,0,1)';
    ctx.stroke();
    ctx.closePath();
  }

  drawTarget(ctx, centerPx) {
    // draw variable radius circle (outer)
    this.drawTargetOuter(ctx, centerPx);

    // draw static radius circle (inner)
    this.drawTargetInner(ctx, centerPx);
  }

  onCircleClipPrecompose(evt) {
    const ctx = evt.context;
    const centerPx = this.map.getPixelFromCoordinate(this.center);

    if (!this.radius) {
      this.setRadius();
    }

    ctx.save();
    ctx.beginPath();
    ctx.arc(
      centerPx[0],
      centerPx[1],
      this.variableRadius,
      0,
      2 * Math.PI
    );
    ctx.clip();
  }

  onCircleClipPostcompose(evt) {
    const ctx = evt.context;
    const centerPx = this.map.getPixelFromCoordinate(this.center);
    this.drawTarget(ctx, centerPx);
    ctx.restore();
  }

  onCircleDrawPostcompose(evt) {
    let ctx = evt.context;
    const centerPx = this.map.getPixelFromCoordinate(this.center);
    this.drawTarget(ctx, centerPx);
    ctx.restore();
  }

  onCircleDrawPrecompose(evt) {
    let ctx = evt.context;
    ctx.save();
  }

  setRadius() {
    let lonlat = toLonLat(this.center);
    let tempCoord = fromLonLat(
      createCoord(lonlat, 90, RISK_BUFFER),
      this.map
        .getView()
        .getProjection()
        .getCode()
    );
    let originPx = this.map.getPixelFromCoordinate(this.center);
    let offsetPx = this.map.getPixelFromCoordinate(tempCoord);
    if (!originPx || !offsetPx) return;
    let radius = Math.ceil(Math.abs(offsetPx[0] - originPx[0]));
    if (radius && radius > 0) {
      this.radius = radius;
    }
  }

  onPointerDrag(evt) {
    let raf = this.getRequestAnimationFrame();
    raf(() => {
      this.setCenter();
    });
  }

  onMoveStart(evt) {
    let raf = this.getRequestAnimationFrame();
    raf(() => {
      this.map.set('wyr-calculating', true);
    });
  }

  onMoveEnd(evt) {
    let raf = this.getRequestAnimationFrame();
    raf(() => {
      this.setCenter();
      this.setRadius();
      this.riskLoaded = false;
      for (let layer of this.riskOpts.riskLayers) {
        this.fetchLayerImg(layer.image, layer);
      }

      if (this.riskOpts.fuel) {
        this.fuelLoaded = false;
        this.fetchLayerImg(this.riskOpts.fuel.image, this.riskOpts.fuel.layer);
      }
    });
  }

  fetchLayerImg(offscreenImg, layer) {
    layer.loaded = false;

    const imageSize = this.imageSize;
    const center = this.center;
    const radius = imageSize / 2 * METERS_PER_PIXEL;

    if (!center || !radius) {
      return;
    }

    // calculate bbox
    const bbox = {
      minx: center[0] - radius,
      miny: center[1] - radius,
      maxx: center[0] + radius,
      maxy: center[1] + radius
    };

    const imgUrl = `${this.mapProxy}${layer.offscreenUrl}&WIDTH=${imageSize}&HEIGHT=${imageSize}&BBOX=${bbox.minx},${bbox.miny},${bbox.maxx},${bbox.maxy}&token=${this.token}`;
    const self = this;
    const xhr = new XMLHttpRequest();
    xhr.onload = e => {
      if (self.imgBlobUrl) {
        URL.revokeObjectURL(self.imgBlobUrl);
      }
      self.imgBlobUrl = URL.createObjectURL(e.target.response);
      if (offscreenImg) {
        offscreenImg.src = self.imgBlobUrl;
      }
    };
    xhr.open('GET', imgUrl, true);
    xhr.responseType = 'blob';
    xhr.send();
  }

  calculateRiskNew(e) {
    for (let layer of this.riskOpts.riskLayers) {
      if (!layer.loaded) {
        return;
      }
    }

    const calcType = this.riskOpts.calculationType;
    if (calcType == 'fuel') {
      if (!this.fuelLoaded) {
        return; //Waiting for fuel to load
      }
    }

    let risks = [];
    for (let layer of this.riskOpts.riskLayers) {
      if (!layer.palette) {
        let palette = this.generatePalette(layer);

        layer.palette = palette;
      }

      let risk = this.calculateAverage(
        e,
        layer.image,
        this.offscreenCanvas,
        layer.palette,
        layer.filterSettings
      );
      risks.push(risk);
    }

    switch (calcType) {
      case "fuel":
        {
          const fuelOpts = this.riskOpts.fuel;
          if (!fuelOpts.palette) {
            let palette = this.generatePalette(fuelOpts.layer);
            fuelOpts.palette = palette;
          }

          let fuel = this.calculateAverage(
            e,
            fuelOpts.image,
            this.offscreenCanvas,
            fuelOpts.palette,
            fuelOpts.filterSettings
          );
          return this.calculateRiskFuels(e, risks, fuel);
        }
      default: {
        return this.calculateRiskSimple(e, risks);
      }
    }
  }

  calculateRisk(e, offscreenImg, offscreenImageFuel, offscreenCanvas) {
    if (!this.riskLoaded || !this.fuelLoaded) {
      //Waiting for a layer to load
      return;
    }

    const riskLayers = this.riskOpts.riskLayers

    if (!this.riskPalettes[layer]) {
      let palette = this.generatePalette(
        riskLayers[this.selectedLayer]
      );

      this.riskPalettes[layer] = palette;
    }

    let risks = [];
    for (let layer = 0; layer < riskLayers.length; layer++) {
      let risk = this.calculateAverage(
        e,
        offscreenImg,
        offscreenCanvas,
        this.riskPalettes[layer],
        riskLayers[layer].filterSettings
      );
      risks.push(risk);
    }

    switch (this.riskOpts.calculationType) {
      case 'fuel': {
        if (!this.fuelPalette) {
          let palette = this.generatePalette(this.riskOpts.fuel.layer);

          this.fuelPalette = palette;
        }

        let fuel = this.calculateAverage(
          e,
          offscreenImageFuel,
          offscreenCanvas,
          this.fuelPalette,
          this.riskOpts.fuel.filterSettings
        );
        return this.calculateRiskFuels(e, risks, fuel);
      }

      default:
        return this.calculateRiskSimple(e, risks);
    }
  }

  calculateRiskSimple(e, risks) {
    const layers = this.riskOpts.riskLayers;
    for (let index = 0; index < layers.length; index++) {
      let risk = risks[index];
      let centerRisk = risk.points[Math.floor(risk.points.length / 2)];
      layers[index].centerRisk = centerRisk;
      layers[index].riskValue = risk;
      layers[index].riskIndex = Math.round(risk.stats.averageIndex);
    }

    this.map.set('riskindex', layers[this.selectedLayer].riskIndex || 0);
    this.map.set('wyr-calculating', false);
  }

  calculateAverage(e, offscreenImage, offscreenCanvas, palette, filterSettings) {
    const filtered = this.filterToPalette(...arguments);
    const stats = this.calculateStats(filtered);
    return { stats, points: filtered };
  }

  isFuelCase1(e, fuelCenterValue) {
    if (
      this.riskOpts.nonBurnable.values &&
      this.riskOpts.nonBurnable.values.includes(fuelCenterValue)
    ) {
      //Case 1
      return true;
    }

    if (this.riskOpts.nonBurnable.ranges) {
      for (let lcv = 0; lcv < this.riskOpts.nonBurnable.ranges.length; lcv++) {
        let val = this.riskOpts.nonBurnable.ranges[lcv];
        if (fuelCenterValue >= val.low && fuelCenterValue <= val.high) {
          return true;
        }
      }
    }
  }

  isFuelCase2or3(e, fuelCenterValue, fuels) {
    if (
      this.riskOpts.urbanAgri.values &&
      this.riskOpts.urbanAgri.values.includes(fuelCenterValue)
    ) {
      let burnable = fuels.stats.burnable > 0;

      if (!burnable) {
        //Case 2
        return 2;
      } else {
        //Case 3
        return 3;
      }
    }

    if (this.riskOpts.urbanAgri.ranges) {
      for (let lcv = 0; lcv < this.riskOpts.urbanAgri.ranges.length; lcv++) {
        let val = this.riskOpts.urbanAgri.ranges[lcv];
        if (fuelCenterValue >= val.low && fuelCenterValue <= val.high) {
          let burnable = fuels.stats.burnable > 0;

          if (!burnable) {
            //Case 2
            return 2;
          } else {
            //Case 3
            return 3;
          }
        }
      }
    }
  }

  isFuelCase4(e, fuelCenterValue, fuels) {
    if (fuels.stats.burnable > 0) {
      let centerBurnable = false;

      if (
        this.riskOpts.burnable.values &&
        this.riskOpts.burnable.values.includes(fuelCenterValue)
      ) {
        return true;
      }

      if (this.riskOpts.burnable && this.riskOpts.burnable.ranges) {
        for (
          let lcv = 0;
          lcv < this.riskOpts.burnable.ranges.length && !centerBurnable;
          lcv++
        ) {
          let val = this.riskOpts.burnable.ranges[lcv];
          if (fuelCenterValue >= val.low && fuelCenterValue <= val.high) {
            return true;
          }
        }
      }
    }
  }

  getFuelCase(e, fuels) {
    let resultCase = -1;
    const centerFuels = fuels.points[Math.floor(fuels.points.length / 2)];

    let fuelCenterValue = null;

    if (!centerFuels.stats.filtered) {
      fuelCenterValue = centerFuels.palette.value.value;
    }

    if (this.isFuelCase1(e, fuelCenterValue)) {
      resultCase = 1;
      //TODO: This isnt configurable ergo bad programming
      switch (centerFuels.palette.value.level) {
        case "Snow/Ice":
          resultCase = resultCase + "a";
          break;
        case "Water":
          resultCase = resultCase + "b";
          break;
        case "Barren":
          resultCase = resultCase + "c";
          break;
      }
    } else {
      let case2or3 = this.isFuelCase2or3(e, fuelCenterValue, fuels);
      if (case2or3 == 2) {
        resultCase = 2;
        switch (centerFuels.palette.value.level) {
          case "Urban/Developed":
            resultCase = resultCase + "a";
            break;
          case "Agriculture":
            resultCase = resultCase + "b";
            break;
        }
      } else if (case2or3 == 3) {
        resultCase = 3;
        switch (centerFuels.palette.value.level) {
          case "Urban/Developed":
            resultCase = resultCase + "a";
            break;
          case "Agriculture":
            resultCase = resultCase + "b";
            break;
        }
      } else if (this.isFuelCase4(e, fuelCenterValue, fuels)) {
        resultCase = 4;
      }
    }

    //TODO: A/B
    if (resultCase != -1) return "Case" + resultCase;
    else return "None";
  }

  calculateRiskFuels(e, risks, fuels) {
    let resultCase = this.getFuelCase(e, fuels);
    const riskLayers = this.riskOpts.riskLayers;
    this.riskOpts.fuel.resultCase = resultCase;

    for (let index = 0; index < riskLayers.length; index++) {
      let risk = risks[index];
      let centerRisk = risk.points[Math.floor(risk.points.length / 2)];
      riskLayers[index].centerRisk = centerRisk;
      riskLayers[index].riskValue = risk;
      riskLayers[index].riskIndex = Math.round(risk.stats.averageIndex);
    }

    if (!resultCase.startsWith("Case1")) {
      this.map.set('riskindex', riskLayers[this.selectedLayer].riskIndex || 0);
    } else {
      this.map.set("riskindex", 0);
    }
    this.map.set("fuelcase", resultCase);
    this.map.set("wyr-calculating", false);
  }

  calculateStats(filtered) {
    const riskBurnable = this.riskOpts.burnable;
    const props = {};
    const levels = {};
    let count = 0;
    let total = 0;
    let totalIndex = 0;
    let levelsCount = 0;
    let values = {};
    let burnable = 0;

    for (let i = 0; i < filtered.length; i++) {
      let point = filtered[i];
      let keys = Object.keys(point.stats);
      for (let c = 0; c < keys.length; c++) {
        if (!props[keys[c]]) {
          props[keys[c]] = 0;
        }

        props[keys[c]]++;
      }

      const ppv = point.palette && point.palette.value ? point.palette.value : null;
      const ppvv = ppv ? ppv.value : null;

      if (!point.stats.filtered) {
        count++;
        total += (ppvv || 0);
        totalIndex += point.palette.index;
      }

      if (point.palette && ppv) {
        const ppvl = ppv.level;

        if (ppvl) {
          if (!levels[ppvl]) {
            levels[ppvl] = 0;
            levelsCount++;
          }

          levels[ppvl]++;
        }

        if (ppvv) {
          if (!values[ppvv]) {
            values[ppvv] = 0;
          }
          values[ppvv]++;

          if (riskBurnable.values && riskBurnable.values.includes(ppvv)) {
            burnable++;
          }

          if (riskBurnable && riskBurnable.ranges) {
            for (let lcv = 0; lcv < riskBurnable.ranges.length; lcv++) {
              const val = riskBurnable.ranges[lcv];
              const value = ppvv;
              if (value >= val.low && value <= val.high) {
                burnable++;
              }
            }
          }
        }
      }
    }

    return {
      props,
      levels,
      count,
      total,
      values,
      burnable,
      average: count > 0 ? total / count : 0,
      averageIndex: count > 0 ? totalIndex / count : 0,
      pure: levelsCount == 1
    };
  }

  filterToPalette(e, offscreenImage, offscreenCanvas, palette, filterSettings) {
    let filtered = [];
    let drawScale = 2;

    let imageSize = this.imageSize;
    let ctx = offscreenCanvas.getContext('2d');

    // render image to canvas and grab pixel data
    ctx.clearRect(0, 0, imageSize, imageSize);
    ctx.drawImage(offscreenImage, 0, 0);
    let rgba = ctx.getImageData(0, 0, imageSize, imageSize).data;

    if (this.riskOpts.debug) {
      ctx.clearRect(
        10 + imageSize,
        0,
        drawScale * 2 * imageSize + 10,
        drawScale * 2 * imageSize
      );
      ctx.drawImage(
        offscreenImage,
        10 + imageSize,
        0,
        imageSize * drawScale,
        imageSize * drawScale
      );
    }

    let filterTransparent = false;
    let filterBlack = false;
    let filterWhite = false;

    if (filterSettings) {
      filterTransparent = filterSettings.filterTransparent;

      filterBlack = filterSettings.filterBlack;

      filterWhite = filterSettings.filterWhite;
    }

    //TODO:
    let circular = this.riskOpts.circular || this.riskOpts.fuel ? this.riskOpts.fuel.radius : false;
    let sizeSquared = imageSize / 2 * (imageSize / 2);

    for (let y = 0; y < imageSize; y++) {
      for (let x = 0; x < imageSize; x++) {
        let point = {
          x: x,
          y: y,
          stats: {}
        };
        filtered.push(point);

        if (circular) {
          let xd = x - imageSize / 2;
          xd *= xd;
          let yd = y - imageSize / 2;
          yd *= yd;

          if (xd + yd > sizeSquared) {
            point.stats = { filtered: true, transparent: true };
            continue;
          }
        }

        let r = rgba[4 * x + imageSize * y * 4],
          g = rgba[4 * x + (imageSize * y * 4 + 1)],
          b = rgba[4 * x + (imageSize * y * 4 + 2)],
          a = rgba[4 * x + (imageSize * y * 4 + 3)];

        point.rgba = {
          r: r,
          g: g,
          b: b,
          a: a
        };

        if (a == 0 && filterTransparent) {
          point.stats = { filtered: true, transparent: true };

          continue;
        }

        if (filterBlack) {
          if (r == 0 && b == 0 && g == 0) {
            point.stats = { filtered: true, black: true };
            continue;
          }
        }

        if (filterWhite) {
          if (r == 255 && b == 255 && g == 255) {
            point.stats = { filtered: true, white: true };
            continue;
          }
        }

        let closestIndex = -1;

        let hexVal = rgbToHex([r, g, b, a]);
        let targetLab = hexToLab(hexVal);
        let diffs = [];
        for (let index = 0; index < palette.length; index++) {
          let rl = palette[index];

          if (rl.pixelColor == hexVal) {
            //exactMatch
            closestIndex = index;
            break;
          }

          let lab = hexToLab(rl.pixelColor);

          if (lab) {
            let diff = DeltaE.getDeltaE00(targetLab, lab);
            diffs.push({ diff: diff, index: index });
          } else {
            diffs.push({ diff: 101, index: index });
          }
        }

        if (closestIndex == -1) {
          //No exact match found
          let indexOfLowest = diffs.reduce(
            (iMin, x, i, arr) => (x.diff < arr[iMin].diff ? i : iMin),
            0
          );
          closestIndex = indexOfLowest;

          point.stats.guessed = true;
        } else {
          point.stats.exact = true;
        }

        if (closestIndex >= 0) {
          point.palette = palette[closestIndex];

          if (this.riskOpts.debug) {
            ctx.fillStyle = palette[closestIndex].pixelColor;
            ctx.fillRect(
              imageSize + 10 + imageSize * drawScale + x * drawScale + 10,
              y * drawScale,
              drawScale,
              drawScale
            );
          }
        } else {
          point.stats.filtered = true;
          point.stats.noMatch = true;
        }
      }
    }
    return filtered;
  }
}